import React from "react";

const HmvsTable = () => {
  return (
    <div className="hmvs-table" style={{ overflowX: "auto" }}>
      <table className="hmvs-table__table">
        <thead>
          <tr>
            <th>Type of Lending</th>
            <th>Repayment Basis</th>
            <th colSpan="4">Loan Size (LTV per cent)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan="2" style={{ borderRight: "none" }}></td>
            <td rowspan="7" style={{ borderLeft: "none", borderTop: "none" }}></td>
            <td>Over GBP1million up to and including GBP2 million</td>
            <td>Over GBP2 million up to and including GBP3 million</td>
            <td>Over GBP3 million</td>
          </tr>
          <tr>
            <td rowspan="3">New Mortgage</td>
            <td>Capital Repayment (Houses / Bungalows)</td>
            <td>85</td>
            <td>75</td>
            <td>70</td>
          </tr>
          <tr>
            <td>Capital Repayment (Flats/Maisonettes)</td>
            <td>75</td>
            <td>75</td>
            <td>70</td>
          </tr>
          <tr>
            <td>Interest Only</td>
            <td>75*</td>
            <td>65*</td>
            <td>50</td>
          </tr>
          <tr>
            <td rowspan="3">Remortgage</td>
            <td>Capital Repayment (Houses / Bungalows)</td>
            <td>85</td>
            <td>75</td>
            <td>70</td>
          </tr>
          <tr>
            <td>Capital Repayment (Flats/Maisonettes)</td>
            <td>75</td>
            <td>75</td>
            <td>70</td>
          </tr>
          <tr>
            <td>Interest Only</td>
            <td>75*</td>
            <td>65*</td>
            <td>50</td>
          </tr>
          <tr>
            <td rowspan="6">Additional borrowing</td>
            <td rowspan="5">
              Capital Repayment (including unencumbered properties and existing mortgage customers borrowing more)
            </td>
            <td>Standard (Houses / Bungalows)</td>
            <td>85</td>
            <td>75</td>
            <td>70</td>
          </tr>
          <tr>
            <td>Standard (Flats / Maisonettes)</td>
            <td>75</td>
            <td>75</td>
            <td>70</td>
          </tr>
          <tr>
            <td>Debt Consolidation</td>
            <td>80 (75 for Flats / Maisonettes)</td>
            <td>75</td>
            <td>70</td>
          </tr>
          <tr>
            <td>Any element of existing Interest Only borrowing</td>
            <td>85 (75 for Flats / Maisonettes)</td>
            <td>75</td>
            <td>70</td>
          </tr>
          <tr>
            <td>Business Use</td>
            <td>75</td>
            <td>75</td>
            <td>70</td>
          </tr>
          <tr>
            <td>Interest Only</td>
            <td rowSpan="2"></td>
            <td>60*</td>
            <td>60*</td>
            <td>50*</td>
          </tr>
          <tr>
            <td>Combined Repayment Type &ndash; New Mortgage, Remortgage and Additional Borrowing</td>
            <td>
              Part Capital Repayment/ Part Interest Only <br />
              <br />
              Please note, the maximum LTV for the interest only part of the loan is restricted to the &lsquo;Interest
              Only&rsquo; maximum banding in line with the borrowing amount
            </td>
            <td>85 (75 for Flats / Maisonettes)</td>
            <td>75</td>
            <td>70</td>
          </tr>
          <tr>
            <td colspan="6" style={{ textAlign: "start" }}>
              * Interest Only borrowing against an Ad hoc Capital Repayment Plan is capped at 50 per cent LTV. Any
              additional borrowing up to the LTV limits above must be structured as follows;
              <br />
              <br />
              On a Capital Repayment basis OR
              <br />
              <br />
              On an interest only basis provided the remainder has a separate acceptable Repayment Plan(s) other than
              'Ad hoc Capital'
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default HmvsTable;
