import React, { Fragment, useState } from 'react'
import Tabs from '../tabs/tabs.component';
import User from '../../assets/user.inline.svg';
import UserMultiple from '../../assets/user-multiple.inline.svg';
import ContactMessage from '../../assets/contact-message.inline.svg';
import ContactPhone from '../../assets/contact-phone.inline.svg';

import '../../styles/app.scss';

export default function ServiceDescSelector() {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  return (


    <Tabs
      tabs={['Why choose HSBC UK', 'Contact our High Value Mortgage Service Team', 'Packaging requirements', 'Products']}
      activeTabIndex={selectedTabIndex}
      setActiveTab={setSelectedTabIndex}
      additionalClassName='tabs--horizontal'
      tabContent={[
        [
          <Fragment key={1}>
            <div className='accordion__container' >
              <div className='accordion__body'>
                <ul>
                  <li>Enhanced Loan to value</li>
                  <li>5.5x income (£100k and above)</li>
                  <li>Multiple income streams considered</li>
                  <li>Future rental income considered – evidenced via ARLA letter / Let to buy mortgage offer</li>
                  <li>Investment income used </li>
                  <li>Overseas customers (approved countries) </li>
                  <li>Non-GBP income accepted, including overseas self-employment and rental income</li>
                  <li>Limited Companies – share of net profit used for income. No minimum shareholding needed to include</li>
                  <li>LLP (200+ partners) – Letter from the Finance Director</li>
                  <li>One years’ self-employment can be accepted (2-year average applied)</li>
                  <li>Trust income included</li>
                  <li>Multiple repayment plans considered.</li>
                </ul>
              </div>
            </div>
          </Fragment>
        ],
        [
          <React.Fragment key={2}>
            <div className='accordion__container' >
              <div className='accordion__body'>
                <div style={{ overflowX: 'auto', maxWidth: '100%' }}>
                  <table className='hmvs__service-table'>
                    <tbody>
                      <div className='border-top'></div>
                      <tr>
                        <td><ContactPhone /> Telephone</td>
                        <td>0345 600 5847 <span style={{ color: '#db0011' }}> - option 5</span> </td>
                      </tr>
                      <tr>
                        <td><ContactMessage /> Email</td>
                        <td><a href="mailto: highvaluemortgageservice@hsbc.com" >highvaluemortgageservice@hsbc.com</a></td>
                      </tr>
                      <tr>
                        <td><User /> Manager</td>
                        <td>Sam Mangham</td>
                      </tr>
                      <tr>
                        <td><UserMultiple /> TBDM's</td>
                        <td>Helen Foletti <br /> Josh Marsh</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <p>Our team are available to answer your queries Monday - Friday, 9am - 5pm.</p>
                <p>We will respond to any emails received to our dedicated account within four hours (during normal working hours).</p>
              </div>
            </div>
          </React.Fragment>
        ],
        [
          <React.Fragment key={3}>
            <div className='accordion__container' >
              <div className='accordion__body'>
                <p>When you submit a full mortgage application, we'll ask you to upload relevant supporting documentation. Please click <a href='/packaging-requirements'>here</a> for specific document requirements.</p>
                <p><br />For the Customer Application Form click <a href="/pdfs/High_value_mortgage_service_application_form.pdf" target="_blank">here<img src="../assets/external.link.svg" alt="Ext"/></a>.</p>
              </div>
            </div>
          </React.Fragment>
        ],
        [
          <React.Fragment key={4}>
            <div className='accordion__container' >
              <div className='accordion__body'>
                <p>Click <a href='/products/product-finder/'>here</a> to access our Product finder tool where you can browse all the mortgage products that match your customer's criteria.</p>
              </div>
            </div>
          </React.Fragment>
        ]
      ]}
    />



  );
}