import React, { useEffect } from "react";
import { useLocation } from "@reach/router";
import Layout from "../components/layout";
import "../styles/app.scss";
import HmvsHeroBanner from "../components/high-value-mortgage-services/hvms-hero-banner.component";
import ServiceDescSelector from "../components/high-value-mortgage-services/service-description-selector.component";
import Collage from "../components/meet-the-team/collage";
import { datas } from "../data/meet-the-team-listing-data";
import { usersForMontage } from "../data/collage-people-data";
import MeetTheTeamCard from "../components/meet-the-team/meet-the-team-card.component";
import HmvsFaq from "../components/high-value-mortgage-services/hmvs-faq.component";
import HmvsTable from "../components/high-value-mortgage-services/hmvs-table.component";

export default function HVMS() {
  if (typeof window !== "undefined") {
    if (window.utag) {
      window.utag.view({ page_subcategory: "green" });
    }
  }

  const location = useLocation();

  useEffect(() => {
    const isGreenPage = location.pathname.includes("green" || "energy-performance-certificate");
    const elem = document.querySelector(".page");

    if (elem) {
      if (isGreenPage) {
        elem.classList.add("page--grey");
      } else {
        elem.classList.remove("page--grey");
      }
    }
  }, [location]);

  return (
    <Layout title="High Value Mortgage Services" metaDescription="HSBC Intermediaries High Value Mortgage Services">
      <div className="home-header hmvs green row">
        <div className="green__hero">
          <HmvsHeroBanner />
        </div>
        <hr className="seperation" />
        <div className="green__card-container" style={{ marginBottom: "0px" }}>
          <h1 className="hmvs__section-title">Loan to value</h1>
          <HmvsTable />
        </div>
        <hr className="seperation" />
        <div className="green__card-container" style={{ marginBottom: "0px" }}>
          <h1 className="hmvs__section-title">Service</h1>
          <p className="hmvs__section-text">
            Our dedicated High Value Mortgage Service Team offer an enhanced proposition, with a straight forward
            three-step approach to ensure the application process runs smoothly.
          </p>
          <p className="hmvs__section-text">
            1. Prior to submitting the application, contact our High Value Mortgage Service Team (details can be found
            below) to discuss the case.
          </p>
          <p className="hmvs__section-text">
            2. During the call, we will complete a{" "}
            <a href="/pdfs/High_value_mortgage_service_application_form.pdf" target="_blank">
              Customer Application Form
              <img src="../assets/external.link.svg" alt="Ext" />
            </a>{" "}
            which will capture financial details and any other information that might assist the underwriter with
            assessing the case. The completed form will then be emailed to you to upload as part of application
            submission.
          </p>
          <p className="hmvs__section-text">
            3. Once the application has been passed for assessment, if required, you will receive a telephone call from
            one of our underwriters to discuss the case in detail. This will ensure a lending decision can be made as
            soon as possible.
          </p>
          <p className="hmvs__section-text">
            4. We will also discuss with you appointing a Solicitor or Conveyancer options for the legal work, please refer
            to Solicitor / Conveyancer for our criteria including where Separate Legal Representation is required.
          </p>
        </div>
        <hr className="seperation" />
        <div className="green__card-container" style={{ marginBottom: "0px", padding: "0px" }}>
          <br />
          <ServiceDescSelector />
        </div>
        <hr className="seperation" />
        <div className="green__card-container" style={{ marginBottom: "0px" }}>
          <h1 className="hmvs__section-title" style={{ borderLeft: "5px solid #db0011", paddingLeft: "40px" }}>
            Frequently Asked Questions
          </h1>
          <HmvsFaq />
        </div>
        <hr className="seperation" />
        <div className="green__card-container" style={{ marginBottom: "0px" }}>
          <h1 className="hmvs__section-title">Meet the team</h1>

          <div className="meet-the-team">
            <MeetTheTeamCard id="" title="" data={datas.dataOfHmvsTeam} subTitle="" additionalClassName="main-card" />
            <Collage users={usersForMontage.hvmsTeamMontage} nogray="true" biopage="true" />
          </div>
        </div>
        <hr className="seperation" />
        <div className="green__card-container" style={{ marginBottom: "80px" }}>
          <h1 className="hmvs__section-title">Testimonials</h1>
          <p className="hmvs__section-text">
            {" "}
            We have already helped a number of our brokers with their more complex cases and here’s some of the great
            things they had to say about HSBC UK’s new High Value Mortgage Service:
          </p>
          <p className="hmvs__section-text">
            “I wanted to thank you again for your input, effort and work on this case. I am used to your industry
            leading methodology and service but in this particular case I am are aware of how you went above and beyond
            and without the deal would not have been offered.”
          </p>
          <p className="hmvs__section-text">
            “I treat all clients the same but this particular client was referred by another client and the success is
            already leading to increased referrals so your input has benefitted our further business also. As you were
            so critical to the case I had given the client insight and so share his note below. I am truly grateful for
            your attention to detail, hard work and perseverance you input for my cases and clients. Thank you!”
          </p>
          <p className="hmvs__section-text">
            “I wanted to take a few minutes out of my day to thank you for your recent help in agreeing a high net worth
            case. We had the call from the underwriter in the last hour to confirm it had been agreed."
          </p>
          <p className="hmvs__section-text">
            "This process has been without doubt the most impressive with HSBC to date and more importantly, the most
            seamless process of all high value, complex cases we have done in the last 6 months. Having the opportunity
            to discuss the case pre-application when we discussed the high value proposition was hugely valuable, and
            from application to this stage has been fantastic. Even with the error we made on the application (keying
            solely instead of jointly) was easy to fix and simple, getting agreement has been seamless and overall the
            experience has given me huge confidence in HSBC’s ability to agree complex cases. You will certainly get
            more of them!”
          </p>
          <p className="hmvs__section-text">
            "As advisers, we are also very quick to criticise and don’t often praise enough and to this I can only say
            what a breath of fresh air it is in dealing with Helen. She is clearly passionate about her work and never
            needs chasing. If she says she will do something, she will action and come back to you within the timescales
            she indicates. The test of any company is dealing with the problems and it is such a weight lifted when
            Helen picks up the gauntlet, as we know she will get the job done. She really is a credit to HSBC and one of
            the reasons HSBC large loans is often our first port of call.“
          </p>
        </div>
      </div>
    </Layout>
  );
}
